<template>
  <div class="page not-found text-center d-flex flex-column fill-height align-center justify-center">
    <v-container class="text-center" style="margin-bottom: 25%">
        <h2 class="mb-4 text-center">Page Not Found (404)</h2>
        <v-btn fill depressed to="/">back to home</v-btn>
    </v-container>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  computed: {
    
  }
}
</script>